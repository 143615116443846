<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-app-bar flat v-if="clusterId">
        <rs-action @click="handleTemplateDownloadClick" :loading="isLoading">
          JSON şablon indir
        </rs-action>

        <rs-action @click="handleTemplateUploadClick" :loading="isLoading">
          JSON şablon yükle
        </rs-action>

        <rs-action-delete
          @click="handleDeleteOldRenterClick"
          :loading="isLoading"
          confirmed
        >
          Eski Kiracı Aktarılmış Devirleri Sil
        </rs-action-delete>

        <rs-action-delete
          @click="handleDeleteOldOwnerClick"
          :loading="isLoading"
          confirmed
        >
          Eski Ev Sahibi Aktarılmış Devirleri Sil
        </rs-action-delete>

        <rs-file
          label="Dosya"
          accept="application/json"
          @change="handleFileChange"
          class="d-none"
          id="importInput"
        />
      </v-app-bar>

      <v-app-bar flat v-else>TYA seçmelisiniz.</v-app-bar>

      <v-card-title>
        <v-icon class="mr-2">mdi-timeline-plus-outline</v-icon>
        Devir
      </v-card-title>

      <v-card-text>
        Bu ekran, <strong>Erol Fırtınaoğlu</strong>'nun devir alacakları ve
        borçları yüklemesi için hazırlanmıştır. Hayırlara vesile olması
        dileğiyle.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";

export default {
  mixins: [hasForm],
  computed: {
    ...mapGetters(["clusterId"]),
  },
  data() {
    return {};
  },
  methods: {
    handleTemplateDownloadClick() {
      if (this.isLoading) return false;
      this.isLoading = true;

      const params = {
        cluster_id: this.clusterId,
      };

      this.$api
        .query("api-super-admin/import/transfers", { params })
        .then((response) => {
          const anchor = document.createElement("a");
          anchor.href = response.data.data.download;
          anchor.target = "_blank";
          // anchor.download =
          //   response.data.data.title + "." + response.data.data.extension;
          anchor.click();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleTemplateUploadClick() {
      document.querySelector("input#importInput").value = null;
      document.querySelector("input#importInput").click();
    },
    handleFileChange(file) {
      if (!file) {
        return;
      }

      const allowedMimes = ["application/json"];

      if (allowedMimes.indexOf(file.type) === -1) {
        this.$toast.error("Sadece JSON yükleyebilirsiniz.");
        return;
      }

      this.isLoading = true;
      const data = new FormData();
      data.append("file", file);
      data.append("cluster_id", this.clusterId);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      this.$api
        .post("api-super-admin/import/transfers", data, config)
        .then(() => {
          this.$toast.success("Başarılı");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleDeleteOldRenterClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      const data = {
        house_user_type_id: 5,
      };

      this.$api
        .delete("api-super-admin/import/transfers", data)
        .then(() => {
          this.$toast.success("Başarılı");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleDeleteOldOwnerClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      const data = {
        house_user_type_id: 4,
      };

      this.$api
        .delete("api-super-admin/import/transfers", data)
        .then(() => {
          this.$toast.success("Başarılı");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
